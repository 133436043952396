import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Support Us | Schoolhouse by the Sea";
  const description =
    "Help keep our content free and without ads with a one-time or monthly donation.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <div
        className="full-width-image-container"
        style={{
          backgroundImage: `url('/img/seafoam-splash.jpg')`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 has-text-centered"
          style={{
            backgroundColor: "#1FA8C2",
            color: "white",
            padding: "1rem 1.5rem",
          }}
        >
          Support Us
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <h2 className="title">Help Keep Our Resources Free</h2>
              </div>
              <div className="content">
                Here at Schoolhouse by the Sea, we are committed to keeping ads
                out of our content as well as providing our resources for free.
                We believe in equal and equitable education opportunities, so
                these pursuits are near and dear to our hearts. If you like what
                we're doing and want to provide a donation it'd be greatly
                appreciated. Help us keep our content accessible by subscribing
                to a monthly donation through{" "}
                <a
                  title="patreon"
                  href="https://www.patreon.com/schoolhousebythesea"
                  aria-label="patreon"
                >
                  Patreon
                </a>{" "}
                or make a one-time contribution with{" "}
                <a
                  title="paypal"
                  href="https://paypal.me/metztechco"
                  aria-label="paypal"
                >
                  PayPal
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
